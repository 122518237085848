import { PureComponent } from 'react';
import { TransformComponent } from 'react-zoom-pan-pinch';

import Image from 'Component/Image';
import { ImageRatio } from 'Component/Image/Image.type';
import { ReactElement } from 'Type/Common.type';
import { setLoadedFlag } from 'Util/Request/LowPriorityLoad';

import { ProductGalleryComponentProps } from './ProductGalleryBaseImage.type';

/** @namespace PlugAndSell2/Component/ProductGalleryBaseImage/Component */
export class ProductGalleryBaseImageComponent extends PureComponent<ProductGalleryComponentProps> {
    render(): ReactElement {
        const { src, alt } = this.props;

        return (
            <TransformComponent>
                <Image
                    src={src}
                    ratio={ImageRatio.IMG_CUSTOM}
                    mix={{
                        block: 'ProductGallery',
                        elem: 'SliderImage',
                        mods: { isPlaceholder: !src },
                    }}
                    isPlaceholder={!src}
                    alt={alt}
                    // eslint-disable-next-line react/jsx-no-bind
                    onImageLoad={() => {
                        setLoadedFlag();
                        window.isPrefetchValueUsed = false;
                    }}
                />
                <img style={{ display: 'none' }} alt={alt} src={src} itemProp="image" />
            </TransformComponent>
        );
    }
}

export default ProductGalleryBaseImageComponent;
