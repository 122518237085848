import { PureComponent } from 'react';

import Html from 'Component/Html';
import { ReactElement } from 'Type/Common.type';

import { CmsBlockComponentProps } from './CmsBlock.type';

import './CmsBlock.style';

/**
 * Cms Block
 * @class CmsBlock
 * @namespace PlugAndSell2/Component/CmsBlock/Component */
export class CmsBlockComponent extends PureComponent<CmsBlockComponentProps> {
    static defaultProps: Partial<CmsBlockComponentProps> = {
        cmsBlock: {},
        blockType: '',
    };

    renderPlaceholder(): ReactElement {
        const { children } = this.props;

        if (children && (!Array.isArray(children) || children.length)) {
            return children;
        }

        return null;
    }

    render(): ReactElement {
        const {
            cmsBlock: { identifier, content, disabled },
            blockType,
        } = this.props;

        if (!content || disabled) {
            return null;
        }

        if (identifier === undefined) {
            return this.renderPlaceholder();
        }

        return (
            <div block="CmsBlock" elem="Wrapper" mods={{ type: blockType }}>
                <Html content={content} />
            </div>
        );
    }
}

export default CmsBlockComponent;
