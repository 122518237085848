import { PureComponent } from 'react';

import Icons from 'Component/Icons';
import { IconName } from 'Component/Icons/Icons.type';
import Link from 'Component/Link';
import TypographyHeader from 'Component/TypographyHeader';
import { Variant } from 'Component/TypographyHeader/TypographyHeader.type';
import { ProductAttachment } from 'Query/ProductList.type';
import { ReactElement } from 'Type/Common.type';
import { isEmpty } from 'Util/Common';

import { ProductDownloadablesComponentProps } from './ProductDownloadables.type';

import './ProductDownloadables.style';

/** @namespace PlugAndSell2/Component/ProductDownloadables/Component */
export class ProductDownloadablesComponent extends PureComponent<ProductDownloadablesComponentProps> {
    renderAttachment(attachment: ProductAttachment, idx: number): ReactElement {
        return (
            <li key={`attachment-${idx}`}>
                <Link block="ProductDownloadables" elem="Attachment" to={attachment.url} isOpenInNewTab>
                    <Icons name={IconName.DOWNLOAD_FILE} width="16px" />
                    {attachment.name}
                </Link>
            </li>
        );
    }

    renderAttachments(): ReactElement {
        const { downloadables } = this.props;

        return (
            <ul block="ProductDownloadables" elem="Attachments">
                {downloadables?.map(this.renderAttachment.bind(this))}
            </ul>
        );
    }

    renderContent(): ReactElement {
        const { name } = this.props;

        return (
            <>
                <TypographyHeader variant={Variant.NORMAL} tag="h3">
                    {name}
                </TypographyHeader>
                {this.renderAttachments()}
            </>
        );
    }

    render(): ReactElement {
        const { areDetailsLoaded, downloadables, productAttachmentEnabled } = this.props;

        if (!areDetailsLoaded || isEmpty(downloadables) || !productAttachmentEnabled) {
            return null;
        }

        return (
            <div block="ProductDownloadables" id="ProductDownloadables">
                {this.renderContent()}
            </div>
        );
    }
}

export default ProductDownloadablesComponent;
