/* eslint-disable react/no-unused-state */

import { PureComponent } from 'react';

import Image from 'Component/Image/Image.container';
import { ImageRatio } from 'Component/Image/Image.type';
import { ReactElement } from 'Type/Common.type';
import { setLoadedFlag } from 'Util/Request/LowPriorityLoad';

import { VideoThumbnailComponentProps } from './VideoThumbnail.type';

import './VideoThumbnail.style';

/**
 * VideoThumbnail component
 * @class VideoThumbnail
 * @namespace PlugAndSell2/Component/VideoThumbnail/Component */
export class VideoThumbnailComponent extends PureComponent<VideoThumbnailComponentProps> {
    /**
     * Renders an icon indicating that the video can be played
     */
    renderPlayIcon(): ReactElement {
        return (
            <span block="VideoThumbnail" elem="PlayIcon">
                {__('Play video')}
            </span>
        );
    }

    render(): ReactElement {
        const {
            media: {
                thumbnail: { url },
                video_content: { video_title },
            },
            onPlayClick,
        } = this.props;

        return (
            <div block="VideoThumbnail">
                <button block="VideoThumbnail" elem="Button" onClick={onPlayClick} title={__('Play video %s', video_title)}>
                    <Image
                        src={url}
                        ratio={ImageRatio.IMG_CUSTOM}
                        mix={{
                            block: 'VideoThumbnail',
                            elem: 'Thumbnail',
                            mods: { isPlaceholder: !url },
                        }}
                        isPlaceholder={!url}
                        alt={video_title}
                        // eslint-disable-next-line react/jsx-no-bind
                        onImageLoad={() => {
                            setLoadedFlag();
                            window.isPrefetchValueUsed = false;
                        }}
                    />
                    {this.renderPlayIcon()}
                </button>
            </div>
        );
    }
}

export default VideoThumbnailComponent;
