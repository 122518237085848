export enum ProductPageTabs {
    INFORMATION = 'information',
    ATTRIBUTES = 'attributes',
    REVIEWS = 'reviews',
}

export const PRODUCT_INSTALLMENT = 'installment';

export const STATIC_SECTION_CMS_BLOCK_ID = 'info_tiles';

export const DEFAULT_PRODUCT_LINKS_SLICK_SETTINGS = {
    responsive: [
        {
            breakpoint: 768,
            settings: {
                arrows: false,
                slidesToShow: 2.5,
            },
        },
        {
            breakpoint: 1023,
            settings: {
                arrows: false,
                swipeToSlide: true,
                slidesToShow: 4,
            },
        },
        {
            breakpoint: 9999,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
            },
        },
    ],
};
