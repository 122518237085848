import { PureComponent } from 'react';

import TranslateOnCursorMove from 'Component/TranslateOnCursorMove';
import { ReactElement } from 'Type/Common.type';

import { ImageZoomPopupComponentProps } from './ImageZoomPopup.type';

import './ImageZoomPopup.style';

/** @namespace PlugAndSell2/Component/ImageZoomPopup/Component */
export class ImageZoomPopupComponent extends PureComponent<ImageZoomPopupComponentProps> {
    render(): ReactElement {
        const { children, activeImageId } = this.props;

        return (
            <div block="ImageZoomPopup">
                <TranslateOnCursorMove
                    activeImageId={activeImageId}
                    itemSelector=".ProductGallery-SliderImage, .VideoThumbnail"
                    targetSelector=".Image-Image"
                >
                    {children}
                </TranslateOnCursorMove>
            </div>
        );
    }
}

export default ImageZoomPopupComponent;
