import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { ProductAttachment } from 'Query/ProductList.type';
import { ReactElement } from 'Type/Common.type';
import { RootState } from 'Util/Store/Store.type';

import ProductDownloadables from './ProductDownloadables.component';
import { ProductDownloadablesComponentProps, ProductDownloadablesContainerProps } from './ProductDownloadables.type';

/** @namespace PlugAndSell2/Component/ProductDownloadables/Container/mapStateToProps */

export const mapStateToProps = (state: RootState) => ({
    productAttachmentEnabled: state.ConfigReducer.product_attachment_enabled,
    device: state.ConfigReducer.device,
});

/** @namespace PlugAndSell2/Component/ProductDownloadables/Container/mapDistpatchToProps */

export const mapDistpatchToProps = () => ({});

/** @namespace PlugAndSell2/Component/ProductDownloadables/Container */

export class ProductDownloadablesContainer extends PureComponent<ProductDownloadablesContainerProps> {
    containerProps(): ProductDownloadablesComponentProps {
        const { areDetailsLoaded, device, name, productAttachmentEnabled } = this.props;

        return {
            areDetailsLoaded,
            device,
            name,
            productAttachmentEnabled,
            downloadables: this._getDownloadales(),
        };
    }

    _getDownloadales(): ProductAttachment[] | undefined {
        const { product } = this.props;

        return product.attachments;
    }

    render(): ReactElement {
        return <ProductDownloadables {...this.containerProps()} />;
    }
}

export default connect(mapStateToProps, mapDistpatchToProps)(ProductDownloadablesContainer);
