import { PureComponent, Suspense } from 'react';
import { StickyContainer } from 'react-sticky';

import CmsBlock from 'Component/CmsBlock';
import ContentWrapper from 'Component/ContentWrapper';
import Loader from 'Component/Loader/Loader.component';
import MobilePopup from 'Component/MobilePopup';
import ProductDownloadables from 'Component/ProductDownloadables';
import ProductGallery from 'Component/ProductGallery';
import ProductReviewForm from 'Component/ProductReviewForm';
import { REVIEW_POPUP_ID } from 'Component/ProductReviews/ProductReviews.config';
import { GROUP_01, GROUP_02 } from 'Component/ProductTabs/ProductTabs.config';
import { ProductTabShape } from 'Component/ProductTabs/ProductTabs.type';
import TextPlaceholder from 'Component/TextPlaceholder';
import { TextPlaceHolderLength } from 'Component/TextPlaceholder/TextPlaceholder.config';
import TypographyHeader from 'Component/TypographyHeader';
import { Variant } from 'Component/TypographyHeader/TypographyHeader.type';
import NoMatchHandler from 'Route/NoMatchHandler';
import { ProductPageTabs } from 'Route/ProductPage/ProductPage.config';
import { LinkedProductType } from 'Store/LinkedProducts/LinkedProducts.type';
import { ReactElement } from 'Type/Common.type';
import { isEmpty } from 'Util/Common';
import { IndexedProduct } from 'Util/Product/Product.type';
import { lowPriorityLazy } from 'Util/Request/LowPriorityRender';

import ProductTags from '../../../packages/product-tags/src/component/ProductTags';
import { DEFAULT_PRODUCT_LINKS_SLICK_SETTINGS, STATIC_SECTION_CMS_BLOCK_ID } from './ProductPage.config';
import { ProductPageComponentProps, ProductPageTab } from './ProductPage.type';

import './ProductPage.style';

export const ProductReviews = lowPriorityLazy(
    () =>
        import(
            /* webpackMode: "lazy", webpackChunkName: "product-misc" */
            'Component/ProductReviews'
        )
);
export const ProductTabs = lowPriorityLazy(
    () =>
        import(
            /* webpackMode: "lazy", webpackChunkName: "product-misc" */
            'Component/ProductTabs'
        )
);
export const ProductAttributes = lowPriorityLazy(
    () =>
        import(
            /* webpackMode: "lazy", webpackChunkName: "product-misc" */
            'Component/ProductAttributes'
        )
);
export const ProductLinks = lowPriorityLazy(
    () =>
        import(
            /* webpackMode: "lazy", webpackChunkName: "product-misc" */
            'Component/ProductLinks'
        )
);
export const ProductInformation = lowPriorityLazy(
    () =>
        import(
            /* webpackMode: "lazy", webpackChunkName: "product-misc" */
            'Component/ProductInformation'
        )
);
export const Popup = lowPriorityLazy(
    () =>
        import(
            /* webpackMode: "lazy", webpackChunkName: "overlays" */
            'Component/Popup/Popup.container'
        )
);
export const ProductActions = lowPriorityLazy(
    () =>
        import(
            /* webpackMode: "lazy", webpackChunkName: "overlays" */
            'Component/ProductActions'
        )
);

/** @namespace PlugAndSell2/Route/ProductPage/Component */
export class ProductPageComponent extends PureComponent<ProductPageComponentProps> {
    tabMap: Record<ProductPageTabs, ProductPageTab> = {
        [ProductPageTabs.ATTRIBUTES]: {
            tabKey: 'ProductAttributes',
            name: __('Details'),
            group: GROUP_01,
            shouldTabRender: () => {
                const { isAttributesTabEmpty } = this.props;

                return !isAttributesTabEmpty;
            },
            render: (key: string) => this.renderProductAttributesTab(key),
            getDynamicLabel: (activeProduct: IndexedProduct) => (!isEmpty(activeProduct.attachments) ? __('Details / Downloads') : __('Details')),
        },
        [ProductPageTabs.INFORMATION]: {
            tabKey: 'ProductInformation',
            name: __('About'),
            group: GROUP_01,
            shouldTabRender: () => {
                const { isInformationTabEmpty } = this.props;

                return !isInformationTabEmpty;
            },
            render: (key: string) => this.renderProductInformationTab(key),
        },

        [ProductPageTabs.REVIEWS]: {
            tabKey: 'ProductReviews',
            name: __('Reviews'),
            group: GROUP_02,
            // Return true since we always show 'Add review' button
            shouldTabRender: () => {
                const { areReviewsEnabled } = this.props;

                return areReviewsEnabled;
            },
            render: (key: string) => this.renderProductReviewsTab(key),
        },
    };

    renderProductName(): ReactElement {
        const {
            dataSource: { name },
        } = this.props;

        return (
            <h1 block="ProductPage" elem="Title" itemProp="name">
                <TextPlaceholder content={name} length={TextPlaceHolderLength.MEDIUM} />
            </h1>
        );
    }

    renderProductBrand(): ReactElement {
        const {
            dataSource: { attributes: { brand: { attribute_value: brand = '' } = {} } = {} },
        } = this.props;

        if (!brand) {
            return null;
        }

        return (
            <>
                <meta itemProp="brand" content={brand} />
                <h4 block="ProductPage" elem="Brand" itemProp="brand">
                    <TextPlaceholder content={brand} />
                </h4>
            </>
        );
    }

    renderProductActionsPlaceholder() {
        return (
            <>
                <div block="ProductPage" elem="SectionPlaceholder" />
                <div block="ProductPage" elem="SectionPlaceholder" />
            </>
        );
    }

    renderProductInformationTab(key: string): ReactElement {
        const {
            dataSource: { description: { html: descHtml = null } = {}, short_description: { html: shortDescHtml = null } = {} },
            areDetailsLoaded,
        } = this.props;

        if (!descHtml && !shortDescHtml) {
            return null;
        }

        return (
            <Suspense fallback={null}>
                <ProductInformation htmlDescription={descHtml || shortDescHtml} areDetailsLoaded={areDetailsLoaded} key={key} name={key} />
            </Suspense>
        );
    }

    renderProductAttributesTab(key: string): ReactElement {
        const { activeProduct, areDetailsLoaded } = this.props;

        return (
            <Suspense fallback={<Loader />} key={key}>
                <div id="Details">
                    <ProductAttributes product={activeProduct} name={__('Details')} areDetailsLoaded={areDetailsLoaded} />
                    <ProductDownloadables product={activeProduct} name={__('Download')} areDetailsLoaded={areDetailsLoaded} />
                </div>
            </Suspense>
        );
    }

    renderProductReviewsTab(key: string): ReactElement {
        const { dataSource, areDetailsLoaded } = this.props;

        return (
            <Suspense fallback={<Loader />} key={key}>
                <ProductReviews product={dataSource} areDetailsLoaded={areDetailsLoaded} key={key} name={key} />
            </Suspense>
        );
    }

    renderProductInstallmentTab(): ReactElement {
        return null;
    }

    shouldTabsRender(): ProductTabShape[] {
        return Object.entries(this.tabMap)
            .map(([id, values]) => ({ id, ...values }))
            .filter(({ shouldTabRender }) => shouldTabRender());
    }

    renderAdditionalSections(): ReactElement {
        const { areDetailsLoaded, device } = this.props;

        return (
            <div block="ProductPage" elem="AdditionalSections">
                <Suspense fallback={null}>
                    <ProductLinks
                        linkType={LinkedProductType.RELATED}
                        title={__('Recommended products')}
                        areDetailsLoaded={areDetailsLoaded}
                        slickSettings={DEFAULT_PRODUCT_LINKS_SLICK_SETTINGS}
                        maxVisibleProducts={device.isMobile ? 2 : 4}
                    />
                </Suspense>
                <Suspense fallback={null}>
                    <ProductLinks
                        linkType={LinkedProductType.UPSELL}
                        title={__('Buy also')}
                        areDetailsLoaded={areDetailsLoaded}
                        slickSettings={DEFAULT_PRODUCT_LINKS_SLICK_SETTINGS}
                        maxVisibleProducts={device.isMobile ? 2 : 4}
                    />
                </Suspense>
                {this.renderStaticSection()}
            </div>
        );
    }

    renderReviewPopup(): ReactElement {
        const { dataSource, isMobile, isTablet, isPopupOpened, hidePopup } = this.props;

        return isMobile || isTablet ? (
            <MobilePopup title={__('Write a review')} isOpen={isPopupOpened} handleMobilePopup={hidePopup}>
                <ProductReviewForm product={dataSource} />
            </MobilePopup>
        ) : (
            <Popup id={REVIEW_POPUP_ID} mix={{ block: 'ProductReviews', elem: 'Popup' }}>
                <ProductReviewForm product={dataSource} />
            </Popup>
        );
    }

    renderNameMobile(): ReactElement {
        const {
            activeProduct: { name },
            isMobile,
            isTablet,
        } = this.props;

        if (!isMobile && !isTablet) {
            return null;
        }

        return (
            <TypographyHeader variant={Variant.NORMAL} mix={{ block: 'ProductActions', elem: 'Title' }} itemProp="name">
                <TextPlaceholder content={name} length={TextPlaceHolderLength.MEDIUM} />
            </TypographyHeader>
        );
    }

    renderSKUMobile(): ReactElement {
        const {
            activeProduct: { sku },
            isMobile,
            isTablet,
        } = this.props;

        if (!isMobile && !isTablet) {
            return null;
        }

        return (
            <h4 mix={{ block: 'ProductActions', elem: 'SubTitle' }} itemProp="sku">
                <TextPlaceholder content={sku && `${__('SKU')}: ${sku}`} length={TextPlaceHolderLength.SHORT} />
            </h4>
        );
    }

    renderProductTagsMobile(): ReactElement {
        const { activeProduct, isMobile, isTablet } = this.props;

        if ((!isMobile && !isTablet) || !activeProduct.id) {
            return null;
        }

        return <ProductTags product={activeProduct} />;
    }

    renderProductPageContent(): ReactElement {
        const { areDetailsLoaded, activeProduct, useEmptyGallerySwitcher, isVariant, showAlternativeDesign } = this.props;

        return (
            <>
                {this.renderNameMobile()}
                {this.renderSKUMobile()}
                {this.renderProductTagsMobile()}
                <ProductGallery
                    product={activeProduct}
                    areDetailsLoaded={areDetailsLoaded}
                    isWithEmptySwitcher={useEmptyGallerySwitcher}
                    showLoader={isVariant}
                    showAlternativeDesign={showAlternativeDesign}
                    withLabels
                />
                {this.renderProductActions()}
            </>
        );
    }

    renderProductActions(): ReactElement {
        const { getLink, dataSource, areDetailsLoaded, setActiveProduct, parameters, showAlternativeDesign } = this.props;

        return (
            <div block="ProductPage" elem="ProductActions">
                <Suspense fallback={this.renderProductActionsPlaceholder()}>
                    <ProductActions
                        getLink={getLink}
                        product={dataSource}
                        parameters={parameters}
                        areDetailsLoaded={areDetailsLoaded}
                        setActiveProduct={setActiveProduct}
                        showAlternativeDesign={showAlternativeDesign}
                    />
                </Suspense>
            </div>
        );
    }

    renderStaticSection(): ReactElement {
        return <CmsBlock identifier={STATIC_SECTION_CMS_BLOCK_ID} />;
    }

    renderProductTabs(withHeader: boolean, group: string): ReactElement {
        const { activeProduct } = this.props;

        return <ProductTabs tabs={this.shouldTabsRender()} withHeader={withHeader} group={group} activeProduct={activeProduct} />;
    }

    wrapWithStickyContainer(content: ReactElement): ReactElement {
        const { showAlternativeDesign } = this.props;

        if (showAlternativeDesign) {
            return <StickyContainer id="StickyContainer">{content}</StickyContainer>;
        }

        return content;
    }

    render(): ReactElement {
        const { dataSource } = this.props;

        return (
            <NoMatchHandler>
                <main block="ProductPage" aria-label="Product page" itemScope itemType="http://schema.org/Product">
                    {this.wrapWithStickyContainer(
                        <>
                            <ContentWrapper
                                wrapperMix={{ block: 'ProductPage', elem: 'Wrapper', mods: { productType: dataSource.type_id || 'unknown' } }}
                                label={__('Main product details')}
                            >
                                {this.renderProductPageContent()}
                            </ContentWrapper>
                            {this.renderProductTabs(true, GROUP_01)}
                        </>
                    )}
                    {this.renderProductTabs(false, GROUP_02)}
                    {this.renderAdditionalSections()}
                    {this.renderReviewPopup()}
                </main>
            </NoMatchHandler>
        );
    }
}

export default ProductPageComponent;
