import { ContextType, ElementType, PureComponent } from 'react';

import { CmsBlockContext } from './CmsBlockProvider.container';
import {
    CmsBlockProviderHocClassContainerFunctions,
    CmsBlockProviderHocClassContainerProps,
    CmsBlockProviderHocClassProps,
} from './CmsBlockProvider.type';

/** @namespace PlugAndSell2/Component/CmsBlockProvider/Hoc/withCmsBlockProvider */
export const withCmsBlockProvider = (Component: ElementType) =>
    class CmsBlockProviderHocClass extends PureComponent<CmsBlockProviderHocClassProps> {
        static contextType = CmsBlockContext;

        static context: ContextType<typeof CmsBlockContext>;

        containerFunctions: CmsBlockProviderHocClassContainerFunctions = {
            hasBlock: this.hasBlock.bind(this),
            getBlock: this.getBlock.bind(this),
        };

        containerProps(): CmsBlockProviderHocClassContainerProps {
            const { blocks } = this.getContext();

            return {
                shared: 'blocks' in this.getContext(),
                blocks,
            };
        }

        hasBlock(name: string) {
            return !!this.getBlock(name);
        }

        getBlock(name: string) {
            const { blocks } = this.getContext();

            return blocks?.filter((block) => !!block)?.find(({ identifier }) => identifier === name);
        }

        getContext(): ContextType<typeof CmsBlockContext> {
            return CmsBlockProviderHocClass.context || {};
        }

        render() {
            return <Component {...this.props} {...this.containerFunctions} {...this.containerProps()} />;
        }
    };

export default withCmsBlockProvider;
