import { PureComponent } from 'react';

import NoMatch from 'Route/NoMatch';
import { ReactElement } from 'Type/Common.type';
import { scrollToTop } from 'Util/Browser';

import { NoMatchHandlerComponentProps } from './NoMatchHandler.type';

/** @namespace PlugAndSell2/Route/NoMatchHandler/Component */
export class NoMatchHandlerComponent extends PureComponent<NoMatchHandlerComponentProps> {
    componentDidMount(): void {
        scrollToTop();
    }

    componentDidUpdate(prevProps: NoMatchHandlerComponentProps): void {
        const {
            location: { pathname: newPathname },
        } = this.props;
        const {
            location: { pathname },
        } = prevProps;

        if (newPathname !== pathname) {
            scrollToTop();
            this.onRouteChanged();
        }
    }

    componentWillUnmount(): void {
        const { noMatch, updateNoMatch } = this.props;

        if (noMatch) {
            updateNoMatch({ noMatch: false });
        }
    }

    /**
     * On browser route change
     * @return {void}
     */
    onRouteChanged(): void {
        const { noMatch, updateNoMatch } = this.props;

        if (noMatch) {
            updateNoMatch({ noMatch: false });
        }
    }

    render(): ReactElement {
        const { children, noMatch } = this.props;

        if (noMatch) {
            return <NoMatch />;
        }

        return children;
    }
}

export default NoMatchHandlerComponent;
