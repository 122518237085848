import PropTypes from 'prop-types';
import { createContext } from 'react';

import { CmsBlockProviderContainerProps, CmsBlockProviderContainerState } from 'Component/CmsBlockProvider/CmsBlockProvider.type';
import CmsBlockQuery from 'Query/CmsBlock.query';
import { CmsBlock } from 'Query/CmsBlock.type';
import DataContainer from 'Util/Request/DataContainer';

import { CmsBlockContextType } from '../CmsBlock/CmsBlock.type';

export const CmsBlockContext = createContext<CmsBlockContextType>({
    blocks: [],
});

/** @namespace PlugAndSell2/Component/CmsBlockProvider/Container */
export class CmsBlockProviderContainer extends DataContainer<CmsBlockProviderContainerProps, CmsBlockProviderContainerState> {
    static propTypes = {
        children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
        identifiers: PropTypes.arrayOf(PropTypes.string),
    };

    static defaultProps = {
        identifiers: [],
    };

    static getContext = () => CmsBlockContext;

    static contextType = CmsBlockContext;

    state = {
        isLoading: true,
        blocks: [],
    };

    componentDidMount() {
        const { identifiers } = this.props;

        this.fetchData<{ cmsBlocks: { items: CmsBlock[] } }>(
            [CmsBlockQuery.getQuery({ identifiers })],
            ({ cmsBlocks: { items: blocks } }) => {
                if (!blocks.length) {
                    return;
                }

                this.setState({ blocks });
            },
            () => {}
        );
    }

    render() {
        const { children } = this.props;
        const { blocks } = this.state;

        return <CmsBlockContext.Provider value={{ blocks }}>{children}</CmsBlockContext.Provider>;
    }
}

export default CmsBlockProviderContainer;
