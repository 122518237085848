import { connect } from 'react-redux';

import { RootState } from 'Util/Store/Store.type';

import TranslateOnCursorMove from './TranslateOnCursorMove.component';
import { TranslateOnCursorMoveMapDispatchProps, TranslateOnCursorMoveMapStateProps } from './TranslateOnCursorMove.type';

/** @namespace PlugAndSell2/Component/TranslateOnCursorMove/Container/mapStateToProps */
export const mapStateToProps = (state: RootState): TranslateOnCursorMoveMapStateProps => ({
    isMobile: state.ConfigReducer.device.isMobile,
});

/** @namespace PlugAndSell2/Component/TranslateOnCursorMove/Container/mapDispatchToProps */
export const mapDispatchToProps = (): TranslateOnCursorMoveMapDispatchProps => ({});

// eslint-disable-next-line @scandipwa/scandipwa-guidelines/always-both-mappings
export default connect(mapStateToProps, mapDispatchToProps)(TranslateOnCursorMove);
