import { PureComponent } from 'react';

import FieldGroup from 'Component//FieldGroup';
import Button from 'Component/Button';
import { ButtonSize, ButtonVariant } from 'Component/Button/Button.type';
import Field from 'Component/Field';
import { FieldType } from 'Component/Field/Field.config';
import Form from 'Component/Form';
import Loader from 'Component/Loader';
import ReviewStar from 'Component/ReviewStar';
import { ReviewRatingValue } from 'Query/Review.type';
import { ReactElement } from 'Type/Common.type';

import { ProductReviewFormComponentProps } from './ProductReviewForm.type';

import './ProductReviewForm.style';

/**
 * ProductReviewForm
 * @class ProductReviewForm
 * @namespace PlugAndSell2/Component/ProductReviewForm/Component */
export class ProductReviewFormComponent extends PureComponent<ProductReviewFormComponentProps> {
    ratingTitleMap = {
        1: __('Awful'),
        2: __('Bad'),
        3: __('Average'),
        4: __('Good'),
        5: __('Awesome'),
    };

    renderReviewStar(options: ReviewRatingValue, rating_id: string): ReactElement {
        const { ratingData, onStarRatingClick } = this.props;
        const { option_id, value } = options;
        const isChecked = !!ratingData[rating_id] && ratingData[rating_id] === option_id;
        const ratingId = rating_id.toString();

        return (
            <ReviewStar
                key={option_id}
                name={ratingId}
                value={value}
                title={this.ratingTitleMap[Number(value) as keyof typeof this.ratingTitleMap]}
                isChecked={isChecked}
                option_id={option_id}
                rating_id={ratingId}
                onStarRatingClick={onStarRatingClick}
            />
        );
    }

    renderReviewRating(): ReactElement {
        const { reviewRatings } = this.props;

        if (!Array.isArray(reviewRatings)) {
            return;
        }

        return (
            reviewRatings &&
            reviewRatings.map((rating) => {
                const { rating_id, rating_code, rating_options } = rating;

                return (
                    <FieldGroup validationRule={{ isRequired: true, selector: '[type="radio"]' }} validateOn={['onChange']} key={rating_id}>
                        <fieldset block="ProductReviewForm" elem="Rating" key={rating_id}>
                            <legend block="ProductReviewForm" elem="Legend">
                                {rating_code}
                            </legend>
                            {rating_options
                                .sort(({ value }, { value: nextValue }) => Number(nextValue) - Number(value))
                                .map((option) => this.renderReviewStar(option, rating_id))}
                        </fieldset>
                    </FieldGroup>
                );
            })
        );
    }

    renderButton(): ReactElement {
        return (
            <Button mix={{ block: 'ProductReviewForm', elem: 'Button' }} variant={ButtonVariant.SECONDARY} size={ButtonSize.MEDIUM} type="submit">
                {__('Publish')}
            </Button>
        );
    }

    renderReviewRatingWrapper(): ReactElement {
        const { reviewRatings } = this.props;

        if (!reviewRatings || reviewRatings?.length === 0) {
            return null;
        }

        return (
            <div block="ProductReviewForm" elem="RatingWrapper">
                {this.renderReviewRating()}
            </div>
        );
    }

    renderReviewFormContent(): ReactElement {
        const { reviewData } = this.props;
        const { nickname = '', detail = '' } = reviewData;

        return (
            <div block="ProductReviewForm" elem="Wrapper">
                <div>{this.renderReviewRating()}</div>
                <div block="ProductReviewForm" elem="Content">
                    <Field
                        type={FieldType.TEXT}
                        attr={{
                            id: 'nickname',
                            name: 'nickname',
                            defaultValue: nickname,
                            placeholder: __('Your nickname'),
                        }}
                        validateOn={['onChange']}
                        validationRule={{
                            isRequired: true,
                        }}
                        mix={{ block: 'ProductReviewForm', elem: 'Field' }}
                    />
                    <Field
                        type={FieldType.TEXTAREA}
                        attr={{
                            id: 'detail',
                            name: 'detail',
                            defaultValue: detail,
                            placeholder: __('Review'),
                        }}
                        validateOn={['onChange']}
                        validationRule={{
                            isRequired: true,
                        }}
                        mix={{ block: 'ProductReviewForm', elem: 'Field' }}
                    />
                </div>
            </div>
        );
    }

    render(): ReactElement {
        const { isLoading, onReviewSubmitSuccess, onReviewError } = this.props;

        return (
            <Form key="product-review" mix={{ block: 'ProductReviewForm' }} onSubmit={onReviewSubmitSuccess} onError={onReviewError}>
                <Loader isLoading={isLoading} />
                {this.renderReviewFormContent()}
                {this.renderButton()}
            </Form>
        );
    }
}

export default ProductReviewFormComponent;
