export const TABS_SCROLL_DEBOUNCE_DELAY = 10;
export const TABS_SCROLL_TOP_TRESHOLD = 170;
export const TABS_SCROLL_BOTTOM_TRESHOLD = 100;
export const SCROLL_TO_TAB_OFFSET = 150;

export const GROUP_01 = 'group_01';
export const GROUP_02 = 'group_02';

export const PRODUCT_TABS_FIRST_GROUP = 'ProductTabs-group_01';
export const PRODUCT_TABS_LAST_GROUP = 'ProductTabs-group_02';
