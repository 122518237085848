import withCmsBlockProvider from 'Component/CmsBlockProvider/CmsBlockProvider.hoc';
import CmsBlockQuery from 'Query/CmsBlock.query';
import { CmsBlockQueryOutput } from 'Query/CmsBlock.type';
import { ReactElement } from 'Type/Common.type';
import DataContainer from 'Util/Request/DataContainer';

import CmsBlock from './CmsBlock.component';
import { CmsBlockComponentProps, CmsBlockContainerProps, CmsBlockContainerState } from './CmsBlock.type';

/** @namespace PlugAndSell2/Component/CmsBlock/Container */
export class CmsBlockContainer extends DataContainer<CmsBlockContainerProps, CmsBlockContainerState> {
    static defaultProps: Partial<CmsBlockContainerProps> = {
        blockType: '',
    };

    state: CmsBlockContainerState = {
        cmsBlock: {},
    };

    __construct(props: CmsBlockContainerProps): void {
        const { identifier } = this.props;
        super.__construct(props, `CmsBlockContainer-${identifier}`);
    }

    containerProps(): CmsBlockComponentProps {
        const { blockType, children } = this.props;
        const { cmsBlock } = this.state;

        return { cmsBlock, blockType, children };
    }

    componentDidMount(): void {
        this._getCmsBlock();
    }

    componentDidUpdate(prevProps: CmsBlockContainerProps): void {
        const { blocks, identifier } = this.props;
        const { blocks: prevBlocks, identifier: prevIdentifier } = prevProps;

        if (prevBlocks && blocks && prevBlocks.length !== blocks.length) {
            this._getCmsBlock();
        }

        if (identifier !== prevIdentifier) {
            this._getCmsBlock();
        }
    }

    _getCmsBlock(): void {
        const { getBlock, hasBlock, identifier, shared } = this.props;

        if (shared) {
            const block = getBlock(identifier);

            if (!hasBlock(identifier) || !block) {
                return;
            }

            this.setState({ cmsBlock: block });
        } else {
            this.fetchData<CmsBlockQueryOutput>([CmsBlockQuery.getQuery({ identifiers: [identifier] })], ({ cmsBlocks: { items } }) => {
                if (!items.length) {
                    return;
                }

                this.setState({ cmsBlock: items[0] });
            });
        }
    }

    render(): ReactElement {
        return <CmsBlock {...this.containerProps()} />;
    }
}

export default withCmsBlockProvider(CmsBlockContainer);
