import { PureComponent } from 'react';

import { ReactElement } from 'Type/Common.type';

import { ReviewStarComponentProps } from './ReviewStar.type';

import './ReviewStar.style';

/** @namespace PlugAndSell2/Component/ReviewStar/Component */
export class ReviewStarComponent extends PureComponent<ReviewStarComponentProps> {
    __construct(props: ReviewStarComponentProps): void {
        super.__construct?.(props);

        this.onStarRatingClick = this.onStarRatingClick.bind(this);
    }

    onStarRatingClick(): void {
        const { rating_id, option_id, onStarRatingClick } = this.props;

        onStarRatingClick(rating_id, option_id);
    }

    render(): ReactElement {
        const { name, value, title, isChecked, rating_id, option_id } = this.props;

        return (
            <input
                block="ReviewStar"
                type="radio"
                id={option_id + rating_id}
                name={name}
                value={value}
                title={title}
                checked={isChecked}
                onChange={this.onStarRatingClick}
                key={option_id}
            />
        );
    }
}

export default ReviewStarComponent;
